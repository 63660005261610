






















































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import SymptomDialog from "@/components/calendar/SymptomDialog.vue";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import Webservice from "@/assets/service/Webservice";
import School from "@/assets/Entities/School";
import {Wrapper} from "@/assets/Entities/GenericEntity";
import User, {Question, QuestionStatus} from "@/assets/Entities/User";
import SchoolClass from "@/assets/Entities/SchoolClass";

@Component({
  components: {SymptomDialog}
})
export default class Calendar extends Vue {
  selectedMonth: number = (new Date()).getMonth();
  selectedYear: number = (new Date()).getFullYear();
  selectedSchool: string = '';
  selectedClass: string = '';
  totalItems: number = 0;
  firstDate: Date = new Date();
  now: Date = new Date();
  items: User[] = [];
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'];

  created() {
    if (this.$store.getters.currentUser.role === UserType.Admin) {
      Webservice.performRequest<Wrapper<School>>(RequestType.GET,
          {type: TargetType.SCHOOL},
          {itemsPerPage: 100},
          val => {
            this.$store.commit('SET_AVAILABLE_SCHOOLS', (val as Wrapper<School>).items);
          });
    } else {
      Webservice.performRequest<Wrapper<SchoolClass>>(RequestType.GET,
          {
            type: TargetType.CLASS,
            schoolId: this.$store.getters.currentUser.schoolId || this.$store.getters.currentUser.school._id
          },
          {},
          val => {
            this.$store.commit('SET_AVAILABLE_CLASSES', (val as Wrapper<SchoolClass>).items);
          })
    }
  }

  get schoolId(): string {
    return this.$store.getters.currentUser.schoolId || this.$store.getters.currentUser.school._id;
  }

  get isAdmin(): boolean {
    return this.$store.getters.currentUser.role === UserType.Admin;
  }

  get availableYears(): number[] {
    let years: number[] = [];
    for (let i = this.firstDate.getFullYear(); i <= this.now.getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  get availableSchools(): School[] {
    return this.$store.getters.availableSchools;
  }

  getSymptomsForDate(item: User, day: number): Question | undefined {
    return item.questions?.filter(value => {
      if (value.date !== undefined) {
        const date = new Date(value.date);
        return (date.getFullYear() === this.selectedYear
            && date.getMonth() === this.selectedMonth
            && date.getDate() === day)
      }
    })[0];
  }

  getBackgroundColorForDate(item: User, day: number): string {
    let question: Question | undefined = this.getSymptomsForDate(item, day);
    if (question && question.status === undefined) {
      return 'background-color: yellow';
    }
    switch (question?.status) {
      case QuestionStatus.GoodToGo:
        return 'background-color: green';
      case QuestionStatus.ShouldCheck:
        return 'background-color: yellow';
      case QuestionStatus.Quarantine:
        return 'background-color: red';
      default:
        return '';
    }
  }

  get availableClasses(): SchoolClass[] {
    return this.$store.getters.availableClasses;
  }

  get header(): DataTableHeader[] {
    let header: DataTableHeader[] = [{
      text: 'Login',
      value: 'login',
      width: '100px',
      divider: true
    }];

    const date = new Date(this.selectedYear, this.selectedMonth + 1, 0);

    for (let i = 1; i <= date.getDate(); i++) {
      header.push({
        text: i.toString(),
        value: '',
        sortable: false,
        width: '30px',
        divider: true,
        align: 'center',
        class: 'slim-column'
      })
    }

    return header;
  }

  disableTab(tab: number): boolean {
    return !((this.now.getFullYear() >= this.selectedYear
        && this.now.getMonth() >= tab)
        && (this.firstDate.getFullYear() <= this.selectedYear
            && this.firstDate.getMonth() <= tab));
  }

  toggleCombobox(cbx: string) {
    const combobox: any = this.$refs[cbx];
    if (combobox.isMenuActive) {
      (this.$refs[cbx] as any).isMenuActive = false;
    } else {
      (this.$refs[cbx] as any).isMenuActive = true;
      combobox.focus();
    }
  }

  changeSchool(): void {
    this.selectedClass = '';
    Webservice.performRequest<Wrapper<SchoolClass>>(RequestType.GET,
        {type: TargetType.CLASS, schoolId: this.selectedSchool},
        {},
        val => {
          this.$store.commit('SET_AVAILABLE_CLASSES', (val as Wrapper<SchoolClass>).items);
        })
  }

  @Watch('selectedClass')
  @Watch('selectedSchool')
  @Watch('selectedMonth')
  @Watch('selectedYear')
  loadData() {
    Webservice.performRequest<QuestionWrapper>(RequestType.GET,
        {
          type: TargetType.QUESTION,
          schoolId: this.selectedSchool || this.$store.getters.currentUser.schoolId || this.$store.getters.currentUser.school._id,
          classId: this.selectedClass
        },
        {
          date_start: this.selectedYear + '.' + this.selectedMonth + '.' + 1,
          date_end: this.selectedYear + '.' + (this.selectedMonth + 1) + '.' + 0
        },
        val => {
          this.items = (val as QuestionWrapper).questions;
          this.firstDate = new Date((val as QuestionWrapper).firstDate);
          this.totalItems = (val as QuestionWrapper).count;
          // this.$store.commit('SET_AVAILABLE_QUESTIONS_LIST', (val as Wrapper<Question>).items);
        });
  }
}

class QuestionWrapper {
  questions!: User[];
  firstDate!: Date | string;
  count!: number;
}
