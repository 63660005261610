


















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Question} from "@/assets/Entities/User";
import Repository from "@/assets/service/Repository";
import Endpoints from "@/assets/service/Endpoints";
import {TargetType} from "@/assets/constants/Enums";
import pdf from "vue-pdf";

@Component({
  components: {pdf}
})
export default class SymptomDialog extends Vue {
  contacts: string[] = [];
  locations: string[] = [];

  dialogOpen: boolean = false;

  testImage: string = '';
  ext: string = '';

  @Watch('dialogOpen', {deep: true})
  setImageURL() {
    if (this.symptoms?.img && this.dialogOpen) {
      this.testImage = Repository.defaults.baseURL + Endpoints.getSchoolURL({
        schoolId: this.schoolId,
        type: TargetType.USER,
        userId: this.userId
      }) + '/test/' + this.date.getDate() + '.' + (this.date.getMonth() + 1) + '.' + this.date.getFullYear();
      if (this.symptoms.ext) {
        this.ext = this.symptoms.ext;
      }
      return;
    }
    this.testImage = '';
  }

  @Prop() userName!: string;
  @Prop() date!: Date;
  @Prop() symptoms!: Question;
  @Prop() schoolId!: string;
  @Prop() userId!: string;
}
